import _ from "lodash"

/**
 * Normalizes en & em-dashes to hyphens (-).
 *
 * @param {string} text The text to be normalized
 * @returns The given text with any en/em dashes normalized to hyphens
 */
export const normalizeHyphens = (text: string): string => {
  return _.replace(text, /\u2013|\u2014/g, "-")
}
