










import _ from "lodash"
import { Vue, Component } from "vue-property-decorator"

@Component({ name: "link-cell" })
class LinkCell extends Vue {
  params!: any
  get route() {
    return {
      name: "return-info",
      params: {
        id: this.cellValue,
      },
    }
  }
  get showAlert() {
    if (!_.isNil(this.params.data)) {
      return this.params.data.statusAlert
    }
    return false
  }
  get cellValue() {
    return !_.isNil(this.params.value) ? this.params.value.toString() : ""
  }
}

export default Vue.component("link-cell", LinkCell)
