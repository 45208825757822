
































































































































































































































































import _ from "lodash"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import gql from "graphql-tag"
import { mapGetters } from "vuex"
import Trunquee from "@/components/Trunquee.vue"
import ProductHeader from "@/components/ProductHeader.vue"
import NewLocationDialog from "@/components/NewLocationDialog.vue"
import s3 from "@/services/s3"
import CXService from "@/services/cx"
import SalesforceService from "@/services/salesforce"
import { CXSalesForceContact } from "@/services/salesforce/types"
import { normalizeHyphens } from "@/helpers/text"
import { debounce } from "lodash-decorators"
import { ISelectedNode, IAccountSelect, IHeader, IDivider } from "./types"
import { cx } from "@/types"

@Component({
  name: "return-create-step",
  components: {
    Trunquee,
    ProductHeader,
    NewLocationDialog,
  },
})
export default class ReturnCreateStep extends Vue {
  private readonly s3service = new s3()
  private readonly cxService = new CXService()
  private readonly sfService = new SalesforceService()
  valid: boolean = false
  newLocationDialogVisible: boolean = false
  selectedProductGroup: ISelectedNode<cx.Returns.ProductGroup> | null = null
  productLineSearch: string | null = null
  productsLoading: boolean = false
  productLines: Array<ISelectedNode<cx.Returns.ProductGroup> | IHeader | IDivider> = []
  selectedAccount: ISelectedNode<IAccountSelect> | null = null
  accountsSearch: string | null = null
  accountsLoading: boolean = false
  accounts: Array<ISelectedNode<IAccountSelect>> = []
  selectedAction: ISelectedNode<cx.Returns.Action> | null = null
  actionsSearch: string | null = null
  actionsLoading: boolean = false
  actions: Array<ISelectedNode<cx.Returns.Action>> = []
  billingLocations: Array<any> = []
  shippingLocations: Array<any> = []
  customerContacts: Array<ISelectedNode<CXSalesForceContact>> = []
  selectedShippingLocation: ISelectedNode<cx.Returns.Location> | null = null
  selectedCustomerContact: ISelectedNode<CXSalesForceContact> | null = null
  comments: string | null = null
  returnLocation: cx.Returns.Location | null = null
  productGroupImages: { [key: string]: any } = {}
  customerTag: string | null = null
  poNumber: string | null = null
  $refs!: {
    caseForm: any
  }
  dialog: boolean = false // manual approval modal visibility
  dialogText: string = ""
  technicalSupportContact: boolean = true // technical support as contact for approval modal (vs rma services)

  rules = {
    required: (value: any) => !!value || "This field is required",
    minLength: (value: any) => !value || value.length <= 255 || "Must be less than 255 characters",
    minLength20: (value: any) => !value || value.length <= 20 || "Must be less than 20 characters",
    noCredit: (value: any) => {
      if (!this.$store.getters["user/isEmployee"] && value?.text === "Credit") {
        this.toggleDialog(true, "Credit RMAs", false) // warning dialog
        return "Credit requests must be submitted by email"
      } else {
        return true
      }
    },
    noSpecialInvestigation: (value: any) => {
      if (value?.text === "Special Investigation Request") {
        // TODO: allow employees to create RMA requests:  (!this.$store.getters["user/isEmployee"] && )
        this.toggleDialog(true, "Special Investigation RMAs") // warning dialog
        return "Special Investigation requests must be submitted by email"
      } else {
        return true
      }
    },
    noBaseStations: (value: any) => {
      if (
        !this.$store.getters["user/isEmployee"] &&
        (value?.text === "Base Station Parts and Accessories" || value?.text === "Base Station Transceivers")
      ) {
        this.toggleDialog(true, "Base station RMA cases") // warning dialog
        return "Base Station requests must be submitted by email"
      } else {
        return true
      }
    },
    noHydroverse: (value: any) => {
      if (!this.$store.getters["user/isEmployee"] && value?.text === "Hydroverse Meters") {
        this.toggleDialog(true, "Hydroverse RMA cases") // warning dialog
        return "Hydroverse requests must be submitted by email"
      } else {
        return true
      }
    },
    noScrapService: (value: any) => {
      if (!this.$store.getters["user/isEmployee"] && value?.text === "Scrap as a Service") {
        this.toggleDialog(true, "Scrap as a Service cases", false) // warning dialog
        return "Scrap as a Service requests must be submitted by email"
      } else {
        return true
      }
    },
    email: (value: any) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "Invalid e-mail."
    },
  }

  toggleDialog(visibility: boolean, dialogText: string, technicalSupportContact: boolean = true) {
    this.technicalSupportContact = technicalSupportContact
    this.dialogText = dialogText
    this.dialog = visibility
  }

  mounted(): void {
    this.updateProducts().then(() => {
      if (this.hasWorkingReturn) {
        this.restoreState()
      }
    })
    this.queryAccount("")
  }

  //Indicates if a previous button should be displayed
  @Prop({ type: Boolean, default: false }) readonly hasPrevious!: boolean
  //Indicates if a next button should be displayed
  @Prop({ type: Boolean, default: false }) readonly hasNext!: boolean

  get hasSelectedAccount(): boolean {
    return !_.isNil(_.get(this.selectedAccount, "value.account.id", null))
  }

  get hasShippingLocation(): boolean {
    return !_.isNil(_.get(this.selectedShippingLocation, "value.id", null))
  }

  @Getter("user/getPreferences")
  preferences!: cx.App.UserPreferences

  @Getter("user/isEmployee")
  isEmployee!: boolean

  @Getter("returns/getWorkingReturn")
  workingReturn!: cx.Returns.Return

  @Getter("returns/hasWorkingReturn")
  hasWorkingReturn!: boolean

  @Watch("valid")
  validWatcher(value: boolean): void {
    this.$emit("valid", value)
  }

  @Watch("accountsSearch")
  accountsSearchWatcher(value: string): void {
    if (!_.isNil(value)) {
      if (!_.isNil(this.selectedAccount)) {
        if (!_.isNil(this.selectedAccount.value) && value !== this.selectedAccount.value.account.name) {
          this.debounceQueryAccount(value)
        }
      } else {
        this.debounceQueryAccount(value)
      }
    }
  }

  @Watch("selectedProductGroup")
  selectedProductGroupWatcher(value: ISelectedNode<cx.Returns.ProductGroup>): void {
    if (!_.isNil(value) && !_.isNil(value.value)) {
      this.$store.commit("returns/updateWorkingReturn", {
        productGroup: value.value,
        location: {
          ...(this.workingReturn ? this.workingReturn.location : {}),
          return: value.value.returnLocation,
        },
      })
      this.queryActions(value.value.id)
    }
  }

  @Watch("selectedAction")
  selectedActionWatcher(
    value: ISelectedNode<cx.Returns.Action> | null,
    oldValue: ISelectedNode<cx.Returns.Action> | null
  ): void {
    if (!_.isNil(value)) {
      if (
        _.isUndefined(oldValue) ||
        !oldValue ||
        (!_.isNil(oldValue.value) && oldValue.value !== null && value.value.id !== oldValue.value.id)
      ) {
        this.$store.commit("returns/updateWorkingReturn", {
          action: value.value,
        })
      }
    }
  }

  @Watch("selectedAccount")
  selectedAccountWatcher(value: ISelectedNode<IAccountSelect>): void {
    if (!_.isNil(value)) {
      let account = value.value
      if (!account.account.isDistributor) {
        this.customerTag = null
      }

      this.$store.commit("returns/updateWorkingReturn", {
        account: account.account,
        location: {
          ...(this.workingReturn ? this.workingReturn.location : {}),
          billing: account.billingLocation,
        },
      })
      this.queryShipToLocations("")
      this.queryCustomerContacts("")
    }
  }

  @Watch("selectedShippingLocation")
  selectedShippingLocationWatcher(value: ISelectedNode<cx.Returns.Location> | null): void {
    if (!_.isNil(value)) {
      this.$store.commit("returns/updateWorkingReturn", {
        location: {
          ...(this.workingReturn ? this.workingReturn.location : {}),
          shipping: value.value,
        },
      })
    }
  }

  @Watch("selectedCustomerContact")
  selectedCustomerContactWatcher(value: ISelectedNode<CXSalesForceContact>) {
    if (value) {
      this.$store.commit("returns/updateWorkingReturn", {
        contactId: value.value.Id,
      })
    }
  }

  @Watch("hasWorkingReturn")
  hasWorkingReturnWatcher(value: boolean): void {
    if (value) this.restoreState()
  }

  @Watch("customerTag", { deep: true })
  @debounce(250)
  customerTagWatcher(value: any): void {
    if (!_.isUndefined(value)) this.$store.commit("returns/updateWorkingReturn", { tag: value })
  }

  @Watch("poNumber", { deep: true })
  @debounce(250)
  poNumberWatcher(value: string) {
    if (value) this.$store.commit("returns/updateWorkingReturn", { poNumber: value })
  }

  @Watch("comments", { deep: true })
  @debounce(250)
  commentsWatcher(value: string | null): void {
    if (!_.isUndefined(value)) this.$store.commit("returns/updateWorkingReturn", { description: value })
  }

  @debounce(150)
  debounceQueryAccount(value: string) {
    this.queryAccount(value)
  }

  @debounce(150)
  queryShipToLocations(value: string) {
    if (!_.isNil(value)) this.updateShipToLocations()
  }

  @debounce(150)
  queryCustomerContacts(value: string) {
    if (!value) this.updateCustomerContacts()
  }

  reset(): void {
    this.shippingLocations = []
    this.customerContacts = []
    this.$refs.caseForm.reset()
  }

  filterProductLine(item: ISelectedNode<cx.Returns.ProductGroup> | any, queryText: string, itemText: string): boolean {
    if (_.has(item, "text") && _.has(item, "value.description")) {
      let cleanQueryText = _.toLower(queryText)
      return (
        _.includes(_.toLower(item.text), cleanQueryText) ||
        _.includes(_.toLower(item.value.description), cleanQueryText)
      )
    }
    return false
  }

  async onLocationCreated(locationId: number): Promise<any> {
    try {
      let shippingLocations: Array<ISelectedNode<cx.Returns.Location>> = await this.updateShipToLocations(false)
      let createdLocation = _.filter(
        (shippingLocations: Array<ISelectedNode<cx.Returns.Location>>, item: ISelectedNode<cx.Returns.Location>) => {
          if (item.value.id === locationId) {
            return item
          }
        }
      )[0]
      this.selectedShippingLocation = createdLocation
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
    }
  }

  async finalizeReturnLocation() {
    if (this.selectedAction?.value.name === "Scrap as a Service") {
      this.$store.commit("returns/updateWorkingReturn", {
        location: {
          ...(this.workingReturn ? this.workingReturn.location : {}),
          return: await this.getScrapServiceReturnLocation(),
        },
      })
    }
  }

  async getScrapServiceReturnLocation(): Promise<cx.Returns.Location | undefined> {
    // Scrap should always be returned to Perimeter Park
    return (await this.cxService.getReturnLocations()).find(
      location => location.description === "RMA RETURN CENTER-PERIMETER PARK"
    )
  }

  updateShipToLocations(useCache: boolean = true): Promise<Array<ISelectedNode<cx.Returns.Location>>> {
    this.shippingLocations = []
    if (this.selectedAccount == null) throw "selectedAccount is null"
    return this.cxService.getAccountLocations(this.selectedAccount.value.account.id!, useCache).then(records => {
      this.shippingLocations = records.reduce((result: Array<ISelectedNode<cx.Returns.Location>>, record) => {
        if (record.address && !record.address.match(/^.*(?:(?:po box)|(?:post office box)|(?:p\.o\.)).*$/gim)) {
          result.push({ value: record, text: record.description })
        }
        return result
      }, [])
      if (this.shippingLocations.length === 1) this.selectedShippingLocation = this.shippingLocations[0]
      return this.shippingLocations
    })
  }

  updateCustomerContacts() {
    this.customerContacts = []
    if (this.selectedAccount === null) throw "selectedAccount is null"
    this.sfService.getAccountContacts(this.selectedAccount.value.account.salesforceId!).then(result => {
      this.customerContacts = result.map(customer => ({ value: customer, text: customer.Name }))
      if (this.customerContacts.length === 1) this.selectedCustomerContact = this.customerContacts[0]
    })
  }

  async updateProducts(): Promise<Array<ISelectedNode<cx.Returns.ProductGroup> | IHeader | IDivider>> {
    try {
      this.productLines = []
      let records: Array<cx.Returns.ProductGroup> = await this.cxService.getProductGroups()
      let groupedProducts = _.groupBy(records, item => item.type)
      let groupCount = 0

      // non-employees should not have access to Water Parts - DIS products, so remove from list
      if (!this.isEmployee) {
        const disWaterIndex = groupedProducts["Water"].findIndex(waterProduct => waterProduct.id === 39) // Water Parts - DIS id was normalized at 39 across all databases
        if (disWaterIndex >= 0) {
          groupedProducts["Water"].splice(disWaterIndex, 1)
        }
      }

      for (let groupName in groupedProducts) {
        this.productLines.push({
          header: groupName,
        })

        for (let productIndex in groupedProducts[groupName]) {
          let product = groupedProducts[groupName][productIndex]
          this.productLines.push({
            value: product,
            text: product.name,
          })
          this.productGroupImages[product.id] = this.s3service.getProductGroupImageUrl(product.id, "small")
        }
        groupCount++
        if (groupCount < _.size(groupedProducts)) {
          this.productLines.push({
            divider: true,
          })
        }
      }
      return this.productLines
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
      throw error
    }
  }

  /**
   * filters out any results where the search string is not in the account name or number
   * (overrides the deafult filter of only checking the text value)
   */
  accountsFilter(item: ISelectedNode<IAccountSelect>, queryText: string, itemText: string): boolean {
    const lowerCaseQueryText = queryText.toLocaleLowerCase()
    return (
      item.value.account.name.toLocaleLowerCase().includes(lowerCaseQueryText) ||
      item.value.account.number?.toLocaleLowerCase().includes(lowerCaseQueryText) ||
      false // just to make up for the null possibilty of account number leading to undefined
    )
  }

  async queryAccount(value: string): Promise<Array<ISelectedNode<IAccountSelect>>> {
    if (!_.isNil(value)) {
      this.accountsLoading = true
      try {
        let accounts: Array<IAccountSelect> = await this.cxService.searchActiveAccounts(normalizeHyphens(value))
        this.accounts = _.map(accounts, account => {
          return {
            value: account,
            text: account.account.name,
            subtext: [
              ...(account.account.number ? ["acct no. " + account.account.number] : []),
              account.billingLocation.derivedFullAddress,
            ].join(" | "),
          }
        })
        this.accountsLoading = false
        return this.accounts
      } catch (error) {
        console.error(error)
        this.$raven.captureException(error)
        throw error
      }
    } else {
      return this.accounts // if already null return the current accounts
    }
  }

  async queryActions(productId: number): Promise<Array<ISelectedNode<cx.Returns.Action>>> {
    try {
      if (!productId) throw "productGroupId is null or undefined"
      this.actionsLoading = true
      let records = await this.cxService.getProductGroupActions(productId)
      this.actions = []
      let selectedActionValid = false
      _.forEach(records, action => {
        let currentAction = action
        if (!currentAction) return
        this.actions.push({
          value: currentAction,
          text: currentAction.name,
          description: currentAction.description || null,
        })
        if (_.has(this.selectedAction, "value.id") && this.selectedAction!.value.id === action.id) {
          selectedActionValid = true
        }
      })
      if (!selectedActionValid) {
        this.selectedAction = null
      }
      this.actionsLoading = false
      return this.actions
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
      throw error
    }
  }

  // TODO: literally does this do anything? I can save/restore a return with this entire method commented out
  restoreState(): void {
    // try to restore product line from current working return
    if (!_.isNil(this.workingReturn.productGroup) && !_.isNil(this.workingReturn.productGroup.id)) {
      let legitProductLines: Array<ISelectedNode<
        cx.Returns.ProductGroup
      >> = this.productLines.filter((item): item is ISelectedNode<cx.Returns.ProductGroup> =>
        Object.prototype.hasOwnProperty.call(item, "value")
      )

      this.selectedProductGroup =
        _.find(legitProductLines, {
          value: { id: this.workingReturn.productGroup.id },
        }) || null
    }

    // try to restore account from working return
    if (!_.isNil(this.workingReturn.account) && !_.isNil(this.workingReturn.account.id)) {
      // have to re-populate account items with stored accounts
      this.queryAccount(this.workingReturn.account.name).then(accounts => {
        this.selectedAccount =
          _.find(accounts, {
            value: { account: { id: this.workingReturn.account.id } },
          }) || null
        // have to wait for ship to locations to be populated before tyring to restore state
        this.updateShipToLocations(false).then(shippingLocations => {
          if (!_.isNil(this.workingReturn.location.shipping)) {
            this.selectedShippingLocation =
              _.find(shippingLocations, {
                value: { id: this.workingReturn.location.shipping.id },
              }) || null
          }
        })
      })
    }

    // try to restore action from working return
    if (!_.isNil(this.workingReturn.action)) {
      if (_.isNil(this.actions) || _.isEmpty(this.actions)) {
        this.queryActions(this.workingReturn.productGroup.id!).then(() => {
          this.selectedAction =
            _.find(this.actions, {
              value: { id: this.workingReturn.action.id },
            }) || null
        })
      } else {
        this.selectedAction =
          _.find(this.actions, {
            value: { id: this.workingReturn.action.id },
          }) || null
      }
    }

    // try to restore comments from working return
    if (!_.isNil(this.workingReturn.description)) {
      // NOTE: comments is not a `return-object` autocomplete (so it can just be restored by setting the data property)
      this.comments = this.workingReturn.description
    }
  }
}
