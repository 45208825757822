















import { Vue, Component } from "vue-property-decorator"

/**
 * This is a component tightly coupled with the 'ReturnAddItemsStep.vue' component
 *   It creates an error list tooltip within an ag-grid cell
 *   It uses a very specific object within ag-grid's context that is passed in
 *
 * We should be able to delete this component if we upgrade ag-grid or switch to vuetify 2.0 for tables....
 */
@Component({
  name: "line-item-cell",
})
export default class LineItemErrorTooltip extends Vue {
  params!: any
  icon: string = ""
  errors: string[] = []

  beforeMount() {
    // currently there is only one kind of error and one kind of warning possible
    if (!this.params.context.mapping[this.params.value].verified) {
      this.icon = "warning"
      this.errors.push("This serial number could not be verified")
    }
    if (this.params.context.mapping[this.params.value].count > 1) {
      this.icon = "error"
      this.errors.splice(0, 0, "This serial number was entered multiple times")
    }
  }
}
