









































































































import _ from "lodash"
import { Vue, Component, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { GridOptions, Column } from "ag-grid"
import moment from "moment"
import PostgrahileDatasource from "@/datasources/postgraphile"
import Grid from "@/components/Grid.vue"
import TrunqueeCell from "@/components/cells/TrunqueeCell.vue"
import LinkCell from "@/components/cells/LinkCell.vue"
import { normalizeHyphens } from "@/helpers/text"
import ReturnDialog from "@/components/ReturnDialog.vue"
import { debounce } from "lodash-decorators"
import { cx } from "@/types"

@Component({
  name: "Return",
  components: {
    Grid,
    ReturnDialog,
  },
  i18n: {
    messages: {
      en: {
        newReturn: "New Return",
        continueReturn: "Continue Return",
        cancelReturn: "Cancel Return",
        searchReturns: "Search Returns",
        clearFilters: "Clear Filters",
      },
      es: {
        newReturn: "Nuevo Retorno",
        continueReturn: "Continuar Regreso",
        cancelReturn: "Cancelar Devolución",
        searchReturns: "Resultados de búsqueda",
        clearFilters: "Filtro Claro",
      },
      ja: {
        newReturn: "新しい返品",
        continueReturn: "リターンを続ける",
        cancelReturn: "キャンセルを返す",
        searchReturns: "検索リターン",
        clearFilters: "フィルターをクリア",
      },
    },
  },
})
export default class Return extends Vue {
  datasource: PostgrahileDatasource | null = null
  datasourceLoading: boolean = false
  searchText: string | null = null
  cancelReturnDialog: boolean = false
  downloadFormat: "csv" | "excel" = "csv"
  downloadMenu: boolean = false
  hasFilters: boolean = false
  showCustomerTag: boolean = false
  showAccountNumber: boolean = false
  gridOptions: GridOptions = {}
  $refs!: {
    returnDialog: ReturnDialog
  }
  created() {
    //invoke instance members in here as dependency injections and reactivity will be resolved
    this.gridOptions = {
      defaultColDef: {
        editable: false,
        suppressMenu: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowSelection: "multiple",
      rowDeselection: true,
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      enableRangeSelection: true,
      enableColResize: true,
      animateRows: true,
      colResizeDefault: "shift",
      floatingFilter: true,
      pagination: true,
      paginationAutoPageSize: true,
      suppressMovableColumns: true,
      toolPanelSuppressSideButtons: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">No rows to show</span>`,
      rowModelType: "serverSide",
      cacheBlockSize: 20,
      maxConcurrentDatasourceRequests: 2,
      getContextMenuItems: params => {
        return _.filter(params.defaultItems, item => item !== "toolPanel")
      },
      maxBlocksInCache: 2,
      columnDefs: [
        {
          headerName: "Return Number",
          field: "caseNumber",
          colId: "caseNumber",
          width: 50,
          cellRendererFramework: LinkCell,
          cellRendererParams: {
            router: this.$router,
          },
        },
        {
          headerName: "Status",
          field: "statusName",
          colId: "statusName",
          width: 70,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Product",
          field: "productName",
          colId: "productName",
          width: 60,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          colId: "quantity",
          width: 30,
          comparator: (valueA, valueB) => {
            return valueA - valueB
          },
          filter: "agNumberColumnFilter",
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Requested Action",
          field: "actionName",
          colId: "actionName",
          width: 60,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Ship To",
          field: "shipName",
          colId: "shipName",
          width: 60,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Account Number",
          field: "accountNumber",
          colId: "accountNumber",
          minWidth: 50,
          maxWidth: 200,
          cellRendererFramework: TrunqueeCell,
          hide: true,
        },
        {
          headerName: "Customer Tag",
          field: "customerTag",
          colId: "customerTag",
          width: 50,
          minWidth: 50,
          cellRendererFramework: TrunqueeCell,
          hide: true,
        },
        {
          headerName: "Date Created",
          field: "created",
          colId: "_created",
          width: 50,
          filter: "agDateColumnFilter",
          cellRenderer: params => {
            if (!_.isNil(params.data)) {
              return moment(params.data._created).format("MM/DD/YYYY")
            }
            return ""
          },
        },
        {
          headerName: "Status Alert",
          field: "statusAlert",
          colId: "statusAlert",
          width: 0,
          hide: true,
        },
      ],
    }
  }
  mounted() {
    let loadingEvent = "return-overview-grid-loading"
    this.datasource = new PostgrahileDatasource(this.$apollo, "vw_returns_overview", [], this.$events, loadingEvent)
    this.datasource.setDefaultSort(["STATUS_ALERT_DESC", "_CREATED_DESC"])
    this.gridOptions.api!.setServerSideDatasource(this.datasource)

    this.showCustomerTag = this.preferences.returns.enable_customer_tag || false
    this.showAccountNumber = this.preferences.returns.enable_account_number || false
    this.$events.on(loadingEvent, value => {
      this.datasourceLoading = value
    })
  }

  @Watch("showCustomerTag")
  toggleCustomerTagColumn(value: boolean) {
    this.gridOptions.columnApi!.setColumnVisible("customerTag", value)
    this.gridOptions.api!.sizeColumnsToFit()
    if (value !== this.preferences.returns.enable_customer_tag) {
      this.$store.dispatch("user/savePreferences", {
        returns: {
          enable_customer_tag: value,
          enable_account_number: this.showAccountNumber,
        },
      })
    }
  }

  @Watch("showAccountNumber")
  toggleAccountNumberColumn(value: boolean) {
    this.gridOptions.columnApi!.setColumnVisible("accountNumber", value)
    this.gridOptions.api!.sizeColumnsToFit()
    if (value !== this.preferences.returns.enable_account_number) {
      this.$store.dispatch("user/savePreferences", {
        returns: {
          enable_account_number: value,
          enable_customer_tag: this.showCustomerTag,
        },
      })
    }
  }

  @Watch("searchText", { deep: true })
  @debounce(500)
  filterSearchItems(searchText: string) {
    let Returns = this
    if (!_.isNil(searchText) && searchText.length > 0) {
      searchText = normalizeHyphens(searchText)
      let textColumns = _.keyBy(
        _.filter(Returns.gridOptions.columnApi!.getAllColumns(), (col: Column) => {
          return col.isVisible() && _.includes(["agTextColumnFilter"], col.getColDef().filter)
        }),
        col => col.getColId()
      )
      let filterModels: Array<{ [key: string]: { includesInsensitive: string } }> = []

      _.forEach(textColumns, (value, key) => {
        filterModels.push(
          Returns.datasource!.getFilter({
            [key]: {
              filter: searchText,
              filterType: "text",
              type: "contains",
            },
          })
        )
      })
      Returns.datasource!.setExternalFilter({ or: filterModels })
    } else {
      Returns.datasource!.setExternalFilter(null)
    }

    Returns.gridOptions.api!.onFilterChanged()
    Returns.filterModified()
  }

  @Getter("user/getPreferences")
  preferences!: cx.App.UserPreferences

  @Getter("returns/hasWorkingReturn")
  hasWorkingReturn!: boolean

  get returnDialogVisible() {
    return this.$route.path === "/return/new"
  }

  cancelReturn() {
    this.$refs.returnDialog.cancelReturn()
    this.$store.dispatch("user/clearState")
    this.cancelReturnDialog = false
  }
  downloadSupportCases() {
    let exportParams = {}

    if (this.downloadFormat === "csv") {
      this.gridOptions.api!.exportDataAsCsv(exportParams)
    } else if (this.downloadFormat === "excel") {
      this.gridOptions.api!.exportDataAsExcel(exportParams)
    }
  }
  filterModified() {
    this.hasFilters = !_.isNil(this.searchText) || !_.isEmpty(this.gridOptions.api!.getFilterModel())
  }
  clearFilters() {
    this.searchText = null
    this.gridOptions.api!.setFilterModel(null)
    this.hasFilters = false
  }
  onReturnCreated() {
    if (!_.isNil(this.gridOptions.api)) {
      this.gridOptions.api.purgeServerSideCache()
      this.gridOptions.api.refreshCells()
    }
  }
}
