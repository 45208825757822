






















































































































































































































































































































































































































import _ from "lodash"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import pluralize from "pluralize"
import xlsx from "xlsx"
import Grid from "@/components/Grid.vue"
import TrunqueeCell from "@/components/cells/TrunqueeCell.vue"
import ProductHeader from "@/components/ProductHeader.vue"
import EmptyState from "@/components/EmptyState.vue"
import s3 from "@/services/s3"
import CXService from "@/services/cx"
import { GridOptions, RowNode } from "ag-grid"
import { IIssueForm } from "./types"
import { cx } from "@/types"
import WarrantyService from "@/services/warranty"
import { WarrantyInfo } from "@/services/warranty/types"
import LineItemErrorTooltip from "@/components/LineItemErrorTooltip.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"

@Component({
  name: "return-add-items-step",
  components: {
    Grid,
    ProductHeader,
    EmptyState,
    LoadingSpinner,
  },
})
export default class ReturnAddItemsStep extends Vue {
  private readonly s3service = new s3()
  private readonly cxService = new CXService()
  private readonly warrantyService = new WarrantyService()
  addQuantityValid: boolean = false
  errorsFiltered: boolean = false
  infoDialog: boolean = false
  addDialog: boolean = false
  addDialogValid: boolean = false
  deleteDialog: boolean = false
  updateDialog: boolean = false
  editDialog: boolean = false
  editDialogValid: boolean = false
  editBulkDialog: boolean = false
  editBulkDialogValid: boolean = false
  selectedNode: RowNode | null = null
  selectedSerialNumber: string | null = null
  selectedIssueType: string | null = null
  selectedIssue: string | null = null
  selectedItemCount: number = 0
  serialNumbers: Array<string> = []
  validity: {
    error: boolean
    empty: boolean
    loading: boolean
    mapping: {
      [key: string]: {
        count: number
        verified: boolean // verified serial number via warranty service
      }
    }
  } = {
    error: true, // no items considered error state
    empty: true,
    loading: false,
    mapping: {},
  }
  newQuantity: number | null = null
  serialNumberRules: Array<Function> = [(v: any) => !!v || "Serial number is required"]
  serialNumbersRules: Array<Function> = [
    (v: any) => {
      if (_.isArray(v)) {
        return v.length > 0 || "At least one serial number is required"
      } else {
        return false
      }
    },
  ]
  quantityRules: Array<Function> = [
    (v: any) => !!v || "Quantity is required",
    (v: any) => (v && /^\d+$/.test(v) && parseInt(v) > 0) || "Quantity must be a whole number greater than zero",
  ]
  issuesLoading: boolean = false
  issueMapping: { [key: string]: Array<cx.Returns.Issue> } = {}
  issueRules: Array<Function> = [(v: any) => !!v || "Issue is required"]
  comments: string | null = null
  transmitOptions: any
  gridOptions: GridOptions = {}
  $refs!: {
    addDialogForm: any
    addQuantityForm: any
    grid: Grid
    editBulkDialogForm: any
    serialNumbersSelect: any
  }
  productGroupVerifyList = [
    // this list is all the product groups that serial number verification will run on
    "accuStream Meters",
    "ally Water Meters",
    "Diaphragm Gas Meters",
    "Distribution Automation Devices",
    "Electric Meters",
    "Electronic Water Registers",
    "Gas SmartPoint Modules",
    "iPERL Water Meters",
    "Residential Mechanical Water Meters and Registers",
    "Sonix Ultrasonic Meters",
    "Water SmartPoint Modules",
  ]

  created(): void {
    this.transmitOptions = {
      clickable: false,
      url: this.$route.fullPath,
      acceptedFileTypes: [
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/x-msexcel",
        "application/x-ms-excel",
        "application/x-excel",
        "application/x-dos_ms_excel",
        "application/xls",
        "application/x-xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
      ],
      autoProcessQueue: false,
      autoQueue: false,
      accept: this.handleUpload,
    }

    this.gridOptions = {
      gridAutoHeight: true,
      rowSelection: "multiple",
      enableRangeSelection: true,
      enableFilter: true,
      enableColResize: true,
      animateRows: false,
      colResizeDefault: "shift",
      suppressMovableColumns: true,
      suppressContextMenu: true,
      toolPanelSuppressSideButtons: true,
      isExternalFilterPresent: () => this.errorsFiltered,
      doesExternalFilterPass: node => this.validity.mapping[node.data.serialNumber].count > 1,
      overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">Please add an item</span>',
      context: this.validity,
      columnDefs: [
        {
          headerName: "Serial Number",
          field: "serialNumber",
          colId: "serialNumber",
          width: 150,
          filter: "agTextColumnFilter",
          suppressMenu: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellClassRules: {
            "invalid-cell": (node: any) => this.validity.mapping[node.data.serialNumber].count > 1,
            "valid-cell": (node: any) => this.validity.mapping[node.data.serialNumber].count === 1,
            "warning-cell": (node: RowNode) => !this.validity.mapping[node.data.serialNumber].verified,
          },
          cellRendererFramework: LineItemErrorTooltip,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          colId: "quantity",
          width: 0,
          filter: "agTextColumnFilter",
          suppressMenu: true,
          hide: true,
        },
        {
          headerName: "Issue Type",
          field: "issueType",
          colId: "issueType",
          width: 200,
          filter: "agTextColumnFilter",
          suppressMenu: true,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Issue",
          field: "issue",
          colId: "issue",
          width: 200,
          filter: "agTextColumnFilter",
          suppressMenu: true,
          cellRendererFramework: TrunqueeCell,
        },
        {
          headerName: "Comments",
          field: "comments",
          colId: "comments",
          width: 500,
          filter: "agTextColumnFilter",
          suppressMenu: true,
          cellRendererFramework: TrunqueeCell,
        },
      ],
      onGridReady: event => {
        event.api.sizeColumnsToFit()
      },
      onGridSizeChanged: event => {
        event.api.sizeColumnsToFit()
      },
      onSelectionChanged: event => {
        this.selectedItemCount = event.api.getSelectedRows().length
        if (this.selectedItemCount === 1) {
          this.updateSelectedItem()
        }
      },
    }
  }

  mounted(): void {
    this.reset()
    this.$on("next", this.storeState)

    if (this.hasWorkingReturn) {
      this.restoreState()
    }
  }

  //Indicates if a previous button should be displayed
  @Prop(Boolean) readonly hasPrevious!: boolean

  //Indicates if a next button should be displayed
  @Prop(Boolean) readonly hasNext!: boolean

  @Getter("returns/getWorkingReturn")
  workingReturn!: cx.Returns.Return

  @Getter("returns/hasWorkingReturn")
  hasWorkingReturn!: boolean

  @Watch("valid")
  validWatcher(value: boolean): void {
    this.$emit("valid", value)
  }

  @Watch("addQuantityValid")
  addQuantityValidWatcher(value: boolean): void {
    this.$emit("valid", value)
  }

  @Watch("selectedIssueType")
  selectedIssueTypeWatcher(value: string | null): void {
    if (value && (this.addDialog || this.editDialog || this.updateDialog)) {
      this.selectedIssue = null
    }
  }

  @Watch("errorsFiltered")
  errorsFilteredWatcher(value: boolean): void {
    if (value && !_.isNil(this.gridOptions.api)) {
      this.gridOptions.api.deselectAll()
    }
    this.gridOptions.api!.onFilterChanged()
  }

  @Watch("hasWorkingReturn")
  hasWorkingReturnWatcher(value: boolean): void {
    if (value) {
      this.restoreState()
    }
  }

  get hasSerialnumber(): boolean {
    return (
      (this.workingReturn && this.workingReturn.productGroup && this.workingReturn.productGroup.hasSerialNumber) ||
      false
    )
  }

  get issueTypes(): Array<string> {
    return _.keys(this.issueMapping)
  }

  get issues(): Array<string> {
    return this.issueMapping && this.selectedIssueType && _.isArray(this.issueMapping[this.selectedIssueType])
      ? _.filter(_.map(this.issueMapping[this.selectedIssueType], "name"), name => {
          return !_.isNil(name)
        })
      : []
  }

  get issuesAvailable(): boolean {
    return (
      (this.selectedIssueType &&
        _.isArray(this.issueMapping[this.selectedIssueType]) &&
        this.issueMapping[this.selectedIssueType].length > 0) ||
      false
    )
  }

  get productLineImage(): string | null {
    if (!this.workingReturn.productGroup.id) return null
    return this.s3service.getProductGroupImageUrl(this.workingReturn.productGroup.id, "medium")
  }

  softReset() {
    this.$refs.addDialogForm.reset()
    if (this.$refs.addQuantityForm) this.$refs.addQuantityForm.reset()
    this.serialNumbers = []
    this.selectedIssueType = null
    this.selectedIssue = null
    this.comments = null
    this.errorsFiltered = false
  }

  reset() {
    this.softReset()
    this.validity = {
      error: true,
      empty: true,
      loading: false,
      mapping: {},
    }
    this.gridOptions.api!.setRowData([])
    this.gridOptions.api!.deselectAll()
    this.gridOptions.api!.redrawRows()
    this.$forceUpdate()
  }

  async populateIssues(): Promise<void> {
    this.issuesLoading = true
    try {
      let issues = await this.cxService.getProductGroupIssues(this.workingReturn.productGroup.id!)
      let mapping: { [key: string]: any } = {}
      _.forEach(_.groupBy(_.orderBy(issues, ["type", "name"]), "type"), (value, key) => {
        mapping[key] = _.map(value, item => {
          return item
        })
      })
      this.issueMapping = mapping
      this.$store.commit("returns/updateWorkingReturn", { issueMapping: mapping })
      this.issuesLoading = false
      this.gridOptions.api!.refreshCells()
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
    }
  }

  updateSelectedItem(): void {
    this.selectedNode = this.gridOptions.api!.getSelectedNodes()[0]
    this.selectedSerialNumber = this.selectedNode.data.serialNumber
    this.selectedIssueType = this.selectedNode.data.issueType
    this.selectedIssue = this.selectedNode.data.issue
    this.comments = this.selectedNode.data.comments
  }

  /**
   * validate function is called when adding new items to the list in order to verify their serial numbers
   */
  async validate(newItems: IIssueForm[]) {
    if (this.$refs.grid.getRowCount() === 0 && newItems.length === 0) {
      // the data model has been cleared and can be reset
      this.reset()
      return []
    }
    let warrantyInformation: WarrantyInfo[]
    let errorExists: boolean = false
    this.validity.empty = false
    this.validity.loading = true

    if (this.workingReturn.productGroup.name in this.productGroupVerifyList) {
      try {
        // try to fetch warranty information for input serial numbers
        warrantyInformation = await this.warrantyService.fetchWarrantyBySerialNumbers(
          this.workingReturn,
          newItems.map(returnItem => returnItem.serialNumber!)
        )
      } catch (error) {
        warrantyInformation = []
      }
    } else {
      warrantyInformation = []
    }

    // we will go through the new items and update our validity mapping with counts and verification data
    const newTableValues = newItems.map(returnItem => {
      if (this.validity.mapping[returnItem.serialNumber!]) {
        this.validity.mapping[returnItem.serialNumber!].count++
        // check the total count because duplicate serial numbers is currently the only error
        errorExists = errorExists || this.validity.mapping[returnItem.serialNumber!].count > 0
      } else {
        const warranty = warrantyInformation.find(
          warrantyObject => warrantyObject.mfgSerialNumber === returnItem.serialNumber
        )
        this.validity.mapping[returnItem.serialNumber!] = {
          count: 1,
          verified: this.workingReturn.productGroup.name in this.productGroupVerifyList ? warranty !== undefined : true,
        }
      }
      return {
        serialNumber: returnItem.serialNumber,
        issueType: returnItem.issueType,
        issue: returnItem.issue,
        comments: returnItem.comments,
      }
    })

    this.validity.error = errorExists
    if (this.errorsFiltered && this.$refs.grid.getDisplayedRowCount() <= 0) {
      this.errorsFiltered = false
    }
    this.gridOptions.context = this.validity // set the grid context to the new validity object so the cell renderers will have the latest
    this.softReset()
    this.gridOptions.api!.redrawRows()
    this.validity.loading = false
    return newTableValues
  }

  handleUpload(file: any) {
    let reader = new FileReader()
    reader.onload = async (event: any) => {
      let content = ""
      let eventResult = event && event.target ? event.target.result : null
      if (!eventResult) throw "event.target.result is not defined"

      _.forEach(new Uint8Array(eventResult), byte => {
        content += String.fromCharCode(byte)
      })
      let workbook = xlsx.read(content, {
        password: process.env.VUE_APP_TEMPLATE_PASSWORD, // wut?
        type: "binary",
      })
      let sheet = workbook.Sheets[workbook.SheetNames[0]]
      let headers = ["serialNumber", "issueType", "issue", "comments"]

      let newItems: any[]
      try {
        newItems = await this.validate(
          xlsx.utils
            .sheet_to_json<IIssueForm>(sheet, { header: headers })
            .filter((row: IIssueForm) => Object.values(row).some(string => !!string))
        )
      } catch (error) {
        console.error(error)
        this.$raven.captureException(error)
        newItems = []
      }

      this.gridOptions.api!.updateRowData({
        add: newItems,
        addIndex: 0,
      })

      this.$events.emit("show-snackbar", {
        text: this.getSnackbarText(newItems.length, "added"),
        color: "success",
      })
    }
    reader.readAsArrayBuffer(file.nativeFile)
  }

  handleSerialNumbers(event: any): void {
    if (!Array.isArray(this.serialNumbers)) this.serialNumbers = []
    this.serialNumbers = this.serialNumbers.concat(
      event.clipboardData
        .getData("text")
        .split(/\s+/)
        .filter((item: string) => !!item)
    )
    setTimeout(() => (this.$refs.serialNumbersSelect.$data.lazySearch = ""), 150)
  }

  handleDownloadTemplate(): void {
    let saveName = "Return Upload Template.xlsx"
    this.s3service.downloadObject("/public/docs/return-upload-template.xlsx", saveName).catch(error => {
      console.error(error)
      this.$raven.captureException(error)
      this.$events.emit("show-snackbar", {
        text: `Failed to download ${saveName}`,
        color: "error",
      })
    })
  }

  /**
   * Adds new items to the grid after verifying their serial numbers
   */
  async addItems() {
    let newItems: any[]
    try {
      // pull the new item data from the form model
      newItems = await this.validate(
        this.serialNumbers.map(serialNumber => ({
          serialNumber: serialNumber,
          issue: this.selectedIssue!,
          issueType: this.selectedIssueType!,
          comments: this.comments!,
        }))
      )
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
      newItems = []
    }

    // add the serial numbers from the form to the table
    this.gridOptions.api!.updateRowData({
      add: newItems,
      addIndex: this.gridOptions.api!.getDisplayedRowCount(),
    })

    this.$events.emit("show-snackbar", {
      text: this.getSnackbarText(newItems.length, "added"),
      color: "success",
    })
  }

  /**
   * updateItem will update the value of a single item in the grid
   *
   * It has some weird logic due to the async stuff with CellClass. Ideally we could use
   *   the newer version of ag-grid to get around these work-arounds, or just a vuetify table.
   *   This is kind of some weird logic, but it does work for now. Holding off until we upgrade either
   *   ag-grid or vuetify
   */
  async updateItem() {
    // simply for easier naming convention
    const currentSerialNumber = this.selectedNode!.data.serialNumber
    const newSerialNumber = this.selectedSerialNumber!

    if (currentSerialNumber !== newSerialNumber) {
      // user changed the serial number so we need to update validity
      this.validity.mapping[currentSerialNumber].count--
      this.validity.mapping[newSerialNumber] = {
        // we are going to add this because CellClass will be checked on setData() call
        count: 1,
        verified: false,
      }
    }

    // this causes an error when used in conjunction with CellRenderer,
    //   because ag-grid removes the cell from the dom before Vue can destroy the component.
    //   it doesn't seem to cause any side-effects, but if someone does a lot of edits it could slow page speeds down.
    //   probably fixed in more recent ag-grid versions, so not gonna worry about it until we upgrade.
    this.selectedNode?.setData({
      serialNumber: this.selectedSerialNumber,
      issueType: this.selectedIssueType,
      issue: this.selectedIssue,
      comments: this.comments,
    })

    // so basically if we make an async call before setData(), it will cause the CellClass stuff to fail
    //  (CellClass rule renderers will get called with a null node)
    // but validate adds the new serial numbers so to have one set of logic creating the validation map
    //  so I add the newSerialNumber enough to pass the initial CellClass rendering and then delete it to actually verify it
    delete this.validity.mapping[newSerialNumber]
    try {
      await this.validate([
        {
          serialNumber: newSerialNumber,
          issueType: this.selectedIssueType!,
          issue: this.selectedIssue!,
          comments: this.comments!,
        },
      ])
    } catch (error) {
      console.error(error)
      this.$raven.captureException(error)
    }

    this.gridOptions.api!.deselectAll()

    this.$events.emit("show-snackbar", {
      text: this.getSnackbarText(this.selectedItemCount, "updated"),
      color: "success",
    })
  }

  removeSelectedRows(): void {
    let rows = this.gridOptions.api!.getSelectedRows()

    this.gridOptions.api!.updateRowData({ remove: rows })
    rows.forEach(row => this.validity.mapping[row.serialNumber].count--)

    this.validate([])

    this.$events.emit("show-snackbar", {
      text: this.getSnackbarText(this.selectedItemCount, "removed"),
      color: "success",
    })
  }

  updateSelectedRows(): void {
    this.gridOptions.api!.getSelectedNodes().forEach(node => {
      // there may be a faster way to update these using a map functon...
      node.setData(
        Object.assign(node.data, {
          issueType: this.selectedIssueType,
          issue: this.selectedIssue,
        })
      )
    })

    this.$events.emit("show-snackbar", {
      text: this.getSnackbarText(this.selectedItemCount, "updated"),
      color: "success",
    })
  }

  buildRowData(): Array<cx.Returns.Item> {
    let rowData: Array<cx.Returns.Item> = []
    if (!this.issueMapping) return rowData
    this.gridOptions.api!.forEachNode(node => {
      let rowDatum: IIssueForm | null = node.data || null
      if (rowDatum) {
        let issueId: number | null = (
          _.find(this.issueMapping[rowDatum.issueType], {
            name: rowDatum.issue,
          }) || ({ id: null } as any)
        ).id
        if (typeof issueId === "number") {
          let item = {
            id: issueId,
            issue: {
              id: issueId,
              name: rowDatum.issue || "",
              type: rowDatum.issueType,
              description: null,
            },
            serialNumber: rowDatum.serialNumber || null,
            quantity: rowDatum.quantity || 0,
            description: rowDatum.comments || null,
            disposition: null,
            itemNumber: null,
            created: null,
            updated: null,
          }

          if (this.workingReturn.productGroup.hasSerialNumber) {
            // clear rows that are missing serial#, issue
            if (
              !(rowDatum.serialNumber && rowDatum.serialNumber.length <= 0) &&
              !(rowDatum.issueType && rowDatum.issueType.length <= 0)
            ) {
              item.quantity = 1
              rowData.push(item)
            }
          } else {
            rowData.push(item)
          }
        }
      }
    })
    return rowData
  }

  showAddDialog(): void {
    this.$refs.addDialogForm.reset()
    this.selectedSerialNumber = null
    this.selectedIssueType = null
    this.selectedIssue = null
    this.comments = null
    this.addDialog = true
  }

  showEditDialog(): void {
    if (this.selectedItemCount === 0) {
      this.$events.emit("show-snackbar", {
        text: this.getSnackbarText(this.selectedItemCount, null),
        color: "error",
      })
    } else if (this.selectedItemCount === 1) {
      this.editDialog = true
    } else {
      this.selectedIssueType = null
      this.selectedIssue = null
      this.$refs.editBulkDialogForm.reset()
      this.editBulkDialog = true
    }
  }

  showDeleteDialog(): void {
    if (this.selectedItemCount === 0) {
      this.$events.emit("show-snackbar", {
        text: this.getSnackbarText(this.selectedItemCount, null),
        color: "error",
      })
    } else {
      this.deleteDialog = true
    }
  }

  // Something should be done to addess this!
  getSnackbarText(itemCount: number, action: string | null): string {
    return itemCount === 0
      ? "No items selected!"
      : `${pluralize("item", itemCount, true)} ${pluralize("was", itemCount)} successfully ${action}`
  }

  storeState(): void {
    if (this.workingReturn.productGroup.hasSerialNumber) {
      this.$store.commit("returns/updateWorkingReturn", {
        rowData: this.buildRowData(),
      })
    } else {
      this.$store.commit("returns/updateWorkingReturn", {
        rowData: [
          {
            quantity: this.newQuantity,
            issue: {
              // I feel a little sketchy pulling the issue ID like this, but given the existing type definitions and design this is the way it has to be done
              // It's possible to check the selected issue name exists within the issue mapping object but that would just have to throw an error anyway
              // Honestly I feel like the best solution is redesign of some of the internal data structure design used here
              // If this isn't an inherantly safe lookup assumption, there are other needs to fix besides an if check that throws a failure
              id: this.issueMapping[this.selectedIssueType!].find(
                (issue: cx.Returns.Issue) => issue.name === this.selectedIssue
              )!.id,
              name: this.selectedIssue,
              type: this.selectedIssueType,
              description: this.comments,
            },
            comments: this.comments,
          },
        ],
      })
    }
  }

  restoreState(): void {
    if (this.workingReturn.productGroup.hasSerialNumber && this.gridOptions.api) {
      this.gridOptions.api!.updateRowData({
        add: this.workingReturn.rowData,
      })
    } else {
      if (this.workingReturn.rowData && _.size(this.workingReturn.rowData) == 1) {
        let row = this.workingReturn.rowData[0]
        this.newQuantity = row.quantity
        this.selectedIssue = row.issue.name
        this.selectedIssueType = row.issue.type
        this.comments = row.description || null
      }
    }
  }
}
