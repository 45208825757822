



























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { GridOptions } from "ag-grid"
import _ from "lodash"
import pluralize from "pluralize"
import Grid from "@/components/Grid.vue"
import ProductHeader from "@/components/ProductHeader.vue"
import { cx } from "@/types"

@Component({
  name: "return-review-step",
  components: {
    Grid,
    ProductHeader,
  },
})
export default class ReturnReviewStep extends Vue {
  confirmDialog: boolean = false
  gridOptions: GridOptions = {}

  created() {
    this.gridOptions = {
      gridAutoHeight: true,
      animateRows: true,
      enableSorting: true,
      enableColResize: true,
      colResizeDefault: "shift",
      floatingFilter: false,
      suppressContextMenu: true,
      toolPanelSuppressSideButtons: true,
      suppressMovableColumns: true,
      columnDefs: [
        {
          headerName: "Serial Number",
          field: "serialNumber",
          colId: "serialNumber",
          width: 150,
          suppressMenu: true,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Quantity",
          field: "quantity",
          colId: "quantity",
          width: 100,
          suppressMenu: true,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Issue Type",
          field: "issue.type",
          colId: "issue.type",
          width: 200,
          suppressMenu: true,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Issue",
          field: "issue.name",
          colId: "issue.name",
          width: 200,
          suppressMenu: true,
          filter: "agTextColumnFilter",
        },
      ],
    }
  }

  //Indicates if a previous button should be displayed
  @Prop({ type: Boolean, default: false }) readonly hasPrevious!: boolean
  //Indicates if a next button should be displayed
  @Prop({ type: Boolean, default: false }) readonly hasNext!: boolean

  get returnItemsText(): string {
    let workingReturn = this.workingReturn
    let itemCount = workingReturn && workingReturn.rowData ? workingReturn.rowData.length : 0
    return `Returning ${pluralize("Item", itemCount, true)}`
  }

  @Getter("returns/getWorkingReturn")
  workingReturn!: cx.Returns.Return

  @Watch("workingReturn", { deep: true })
  workingReturnWatcher() {
    if (!_.isNil(this.gridOptions.api) && !_.isNil(this.workingReturn)) this.updateGrid()
  }
  @Watch("gridOptions.api", { deep: true })
  gridAPIWatcher() {
    if (!_.isNil(this.gridOptions.api) && !_.isNil(this.workingReturn)) this.updateGrid()
  }

  private updateGrid() {
    // FIXED: setting rowData based on computed property is messy but it gets the job done
    if (this.workingReturn && this.workingReturn.rowData && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.workingReturn.rowData)
      if (!_.isNil(this.workingReturn.productGroup)) {
        if (this.workingReturn.productGroup.hasSerialNumber) {
          this.gridOptions.columnApi!.setColumnVisible("quantity", false)
          this.gridOptions.columnApi!.setColumnVisible("serialNumber", true)
        } else {
          this.gridOptions.columnApi!.setColumnVisible("serialNumber", false)
          this.gridOptions.columnApi!.setColumnVisible("quantity", true)
        }
      }
    }
  }

  reset(): void {
    //empty method signature to be invoked by parent
  }
}
