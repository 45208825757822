












































































































































import _ from "lodash"
import { Vue, Component, Prop, Model } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { cx } from "@/types"
import ReturnCreateStep from "@/components/steps/return/ReturnCreateStep.vue"
import ReturnAddItemsStep from "@/components/steps/return/ReturnAddItemsStep.vue"
import ReturnReviewStep from "@/components/steps/return/ReturnReviewStep.vue"
import ReturnCompleteStep from "@/components/steps/return/ReturnCompleteStep.vue"

@Component({
  name: "return-dialog",
  components: {
    ReturnCreateStep,
    ReturnAddItemsStep,
    ReturnReviewStep,
    ReturnCompleteStep,
  },
})
export default class ReturnDialog extends Vue {
  step: number = 1
  cancelDialog: boolean = false
  fab: boolean = false
  selectedItemCount: number = 1
  snackbar: any = {
    visible: false,
    timeout: 3000,
    mode: "multi-line",
    text: null,
    action: null,
    result: null,
    icon: null,
  }

  $refs!: {
    createReturn: ReturnCreateStep
    addItems: ReturnAddItemsStep
    reviewReturn: ReturnReviewStep
    completeReturn: ReturnCompleteStep
  }

  @Prop(Boolean) readonly visible!: boolean

  @Getter("returns/getWorkingReturn")
  workingReturn!: cx.Returns.Return

  reset() {
    this.$refs.createReturn.reset()
    this.$refs.addItems.reset()
    this.$refs.reviewReturn.reset()
    this.$refs.completeReturn.reset()
  }
  closeDialog() {
    this.$router.replace("/return")
  }
  cancelReturn() {
    this.closeDialog()
    setTimeout(() => {
      this.step = 1
      this.$store.dispatch("user/clearState")
      this.reset()
    }, 1000)
  }
  getAddItemsSelectedItemCount() {
    if (this.$refs.addItems) {
      return this.$refs.addItems.selectedItemCount
    } else {
      return 0
    }
  }
  saveReturn() {
    if (this.step >= 2) {
      // stores the add items steps state since no event is currently raised for it
      this.$refs.addItems.storeState()
    }
    this.$store.dispatch("user/saveState")
    this.closeDialog()
  }
  displaySnackbar(settings: { color: string; text: string }) {
    this.$events.emit("show-snackbar", _.assign(this.snackbar, settings))
  }
  onReturnCreated(referenceNumber: string) {
    this.$emit("return-created", referenceNumber)
  }
}
