import _ from "lodash"
import gql from "graphql-tag"
import moment from "moment"
import pluralize from "pluralize"
import { normalizeHyphens } from "@/helpers/text"
import VueEvents from "vue-events"
import { IServerSideGetRowsParams, SerializedTextFilter } from "ag-grid"

interface IEventFilter extends SerializedTextFilter {
  filterType: string
  filterTo?: string
  values?: Array<any>
}

interface IDateEventFilter extends IEventFilter {
  dateFrom: string | null
  dateTo: string | null
}

interface IOperatorEventFilter extends IEventFilter {
  operator: string
  condition1: IEventFilter
  condition2: IEventFilter
}

interface ISortBy {
  colId: string
  sort: "desc" | "asc"
}
/**
 * An Ag-Grid server-side datasource class that is responsible for fetching data
 * from a single endpoint while applying the grid's passed filterModel and sortModel.
 *
 * @export
 * @class PostgraphileDatasource
 */
import { DollarApollo } from "vue-apollo/types/vue-apollo"
export default class PostgraphileDatasource {
  apollo: DollarApollo<any>
  modelName: string
  ignoreColumns: Array<any>
  eventBus: VueEvents | null
  loadingEvent: string | null
  externalFilter: any = null
  defaultSort: Array<string> | null = null

  /**
   *Creates an instance of PostgraphileDatasource.
   *
   * @param {*} apollo The Apollo provider instance to use for querying the endpoint
   * @param {*} modelName The model name of the endpoint (eg. "return_overviews")
   * @param {*} [ignoreColumns=[]] The columns to not retrieve from the endpoint
   * @param {*} [loadingEvent=null] The name of the global event which indicates that data is being queried
   * @memberof PostgraphileDatasource
   */
  constructor(
    apollo: DollarApollo<any>,
    modelName: string,
    ignoreColumns: Array<any> = [],
    eventBus: VueEvents | null = null,
    loadingEvent: string | null = null
  ) {
    if (!_.isString(modelName)) {
      throw new Error("modelName is required")
    }
    this.apollo = apollo
    this.modelName = _.replace(_.startCase(modelName), /\s/g, "")
    this.ignoreColumns = ignoreColumns || []
    this.eventBus = eventBus
    this.loadingEvent = loadingEvent
  }

  /**
   * Gets the required Postgraphile orderBy argument given the grid's sortModel.
   *
   * @param {*} sortModel The grid's current sortModel
   * @returns The Postgraphile orderBy argument
   * @memberof PostgraphileDatasource
   */
  getOrderBy(sortModel: Array<ISortBy>) {
    const orderBy: Array<string> = []
    _.forEach(sortModel, sort => {
      const match = /^(_+)?(?:[^_]+)(_+)?$/.exec(sort.colId)
      let sortName = _.toUpper(`${_.snakeCase(sort.colId)}_${sort.sort}`)
      if (!_.isNil(match![1])) {
        sortName = `${match![1]}${sortName}`
      }
      if (!_.isNil(match![2])) {
        sortName = `${sortName}${match![2]}`
      }
      orderBy.push(sortName)
    })
    return orderBy
  }

  /**
   * Handles building the required Postgrahile filter for an Ag-Grid text filter field.
   *
   * @param {*} colId The column name of the column being filtered
   * @param {*} filter The filter instance
   * @returns The Postgraphile text filter
   * @memberof PostgraphileDatasource
   */
  filterTextHandler(colId: string, filter: IEventFilter) {
    const filterTypes: { [key: string]: string } = {
      contains: "includesInsensitive",
      notContains: "notIncludesInsensitive",
      equals: "equalTo",
      notEqual: "notEqualTo",
      startsWith: "startsWithInsensitive",
      endsWith: "endsWithInsensitive",
    }
    return {
      [colId]: {
        [filterTypes[filter.type]]: normalizeHyphens(filter.filter),
      },
    }
  }

  /**
   * Handles building the required Postgraphile filter for an Ag-Grid number filter field.
   *
   * @param {*} colId The column name of the column being filtered
   * @param {*} filter The filter instance
   * @returns The Postgraphile number filter
   * @memberof PostgraphileDatasource
   */
  filterNumberHandler(colId: string, filter: IEventFilter) {
    const filterTypes: { [key: string]: string } = {
      equals: "equalTo",
      notEqual: "notEqualTo",
      lessThan: "lessThan",
      greaterThan: "greaterThan",
      lessThanOrEqual: "lessThanOrEqualTo",
      greaterThanOrEqual: "greaterThanOrEqualTo",
    }

    if (filter.type === "inRange") {
      return {
        and: [
          { [colId]: { greaterThanOrEqualTo: filter.filter } },
          { [colId]: { lessThanOrEqualTo: filter.filterTo } },
        ],
      }
    }

    return { [colId]: { [filterTypes[filter.type]]: filter.filter } }
  }

  /**
   * Handles building the required Postgraphile filter for an Ag-Grid date filter field.
   *
   * @param {*} colId The column name of the column being filtered
   * @param {*} filter The filter instance
   * @returns The Postgraphile date filter
   * @memberof PostgraphileDatasource
   */
  filterDateHandler(colId: string, filter: IDateEventFilter) {
    const filterTypes: { [key: string]: string } = {
      equals: "equalTo",
      notEqual: "notEqualTo",
      lessThan: "lessThan",
      greaterThan: "greaterThan",
    }

    // NOTE: it is safe to deal with `equals` and `notEqual` as dates (not datetimes)
    const dateFrom = moment(filter.dateFrom || undefined)
    if (filter.type === "inRange") {
      const dateTo = moment(filter.dateTo || undefined)
      return {
        and: [{ [colId]: { greaterThan: dateFrom.toISOString() } }, { [colId]: { lessThan: dateTo.toISOString() } }],
      }
    } else if (filter.type === "equals") {
      return {
        and: [
          { [colId]: { greaterThan: dateFrom.startOf("day").toISOString() } },
          { [colId]: { lessThan: dateFrom.endOf("day").toISOString() } },
        ],
      }
    } else if (filter.type === "notEqual") {
      return {
        or: [
          { [colId]: { greaterThan: dateFrom.endOf("day").toISOString() } },
          { [colId]: { lessThan: dateFrom.startOf("day").toISOString() } },
        ],
      }
    }
    return { [colId]: { [filterTypes[filter.type]]: dateFrom.toISOString() } }
  }

  /**
   * Handles building the required Postgraphile filter for an Ag-Grid set filter field.
   *
   * @param {*} colId The column name of the column being filtered
   * @param {*} filter The filter instance
   * @returns The Postgraphile set filter
   * @memberof PostgraphileDatasource
   */
  filterSetHandler(colId: string, filter: IEventFilter) {
    const booleanFilters = ["true", "false"]
    if (!filter.values) return { [colId]: { in: [] } }
    const booleanIntersection = _.intersection(filter.values, booleanFilters)
    if (booleanIntersection.length <= booleanFilters.length) {
      // NOTE: since ag-grid has no concept of a boolean filter, we are *implicitly* using a set filter with the default values of 'true' and 'false'
      filter.values = _.map(booleanIntersection, value => value === "true")
    }
    return { [colId]: { in: filter.values } }
  }

  /**
   * Gets the Postgraphile filters all columns in the Ag-Grid provided filterModel.
   *
   * @param {*} filterModel The grid filterModel
   * @returns The Postgrpahile filter
   * @memberof PostgraphileDatasource
   */
  getFilter(filterModel: { [key: string]: IEventFilter }) {
    const filterHandlers: { [key: string]: Function } = {
      text: this.filterTextHandler,
      number: this.filterNumberHandler,
      date: this.filterDateHandler,
      set: this.filterSetHandler,
    }
    const filter: any = {}
    _.forEach(filterModel, (value: IEventFilter, key: string) => {
      if (_.includes(_.keys(filterHandlers), value.filterType)) {
        _.assign(filter, filterHandlers[value.filterType](key, value))
      } else if (_.has(value, "operator")) {
        const opEventFilter = <IOperatorEventFilter>value
        _.assign(filter, {
          [_.toLower(opEventFilter.operator)]: [
            this.getFilter({ [key]: opEventFilter.condition1 }),
            this.getFilter({ [key]: opEventFilter.condition2 }),
          ],
        })
      } else {
        console.warn(`Postgraphile Datasource: encountered unhandled filterType '${value.filterType}'`, value)
      }
    })
    return filter
  }

  /**
   * Sets the externalFilter property.
   * NOTE: This filter overrides the Ag-Grid passed filterModel
   *
   * @param {*} filter The filter to use instead of the computed one. and/or filter
   * @memberof PostgraphileDatasource
   */
  setExternalFilter(filter: any) {
    this.externalFilter = filter
  }

  /**
   * Sets the defaultSort property.
   * NOTE: This property is the sort which is applied when no sortModel is provided.
   *
   * @param {*} sort The default sort to use when sortModel is empty
   * @memberof PostgraphileDatasource
   */
  setDefaultSort(sort: Array<string>) {
    this.defaultSort = sort
  }

  /**
   * Retrieves data from the Postgraphile endpoint.
   *
   * @param {*} params The Ag-Grid server-side params.
   * @memberof PostgraphileDatasource
   */
  async getRows(params: IServerSideGetRowsParams) {
    if (!_.isNil(this.eventBus) && !_.isNil(this.loadingEvent)) {
      this.eventBus.$emit(this.loadingEvent, true)
    }

    // build endpoint name based on model name
    const endpoint = `all${pluralize(this.modelName)}`
    const request = params.request
    //ignore this since ag grid doesnt offer a accessor method. we need to access the private member directly
    //@ts-ignore
    const columns = _.keyBy(params.parentNode.columnApi.getAllColumns(), col => col.colId)
    const columnIds: Array<string> = []
    _.forEach(columns, col => {
      if (!_.includes(this.ignoreColumns, col.colId)) {
        columnIds.push(col.colId)
      }
    })

    // determine filter and sort to use for query
    let filter = null
    const localFilter = this.getFilter(request.filterModel)
    if (!_.isNil(this.externalFilter)) {
      const andFilters = [this.externalFilter]
      if (!_.isEmpty(localFilter)) {
        andFilters.push(localFilter)
      }
      filter = {
        and: andFilters,
      }
    } else {
      filter = localFilter
    }
    let sort = null
    if (_.isEmpty(request.sortModel)) {
      sort = this.defaultSort
    } else {
      sort = this.getOrderBy(request.sortModel)
    }

    // default query variables
    const queryVariables: any = {
      first: request.endRow - request.startRow,
      offset: request.startRow,
      orderBy: sort,
    }
    // default query string
    let queryString = gql`query (
      $first: Int!,
      $offset: Int!,
      $orderBy: [${pluralize(this.modelName)}OrderBy!],
    ) {
      ${endpoint}(
        first: $first,
        offset: $offset,
        orderBy: $orderBy
      ) {
        nodes {
          ${_.join(columnIds, "\n")}
        }
      }
    }`

    if (!_.isEmpty(filter)) {
      // modifry query string and variables to include filtering
      queryVariables.filter = filter
      queryString = gql`query (
        $first: Int!,
        $offset: Int!,
        $orderBy: [${pluralize(this.modelName)}OrderBy!],
        $filter: ${this.modelName}Filter!
      ) {
        ${endpoint}(
          first: $first,
          offset: $offset,
          orderBy: $orderBy,
          filter: $filter
        ) {
          nodes {
            ${_.join(columnIds, "\n")}
          }
        }
      }`
    }

    // make the query
    try {
      const response = await this.apollo.query({
        variables: queryVariables,
        fetchPolicy: "network-only",
        query: queryString,
      })

      const records = response.data[endpoint].nodes
      const rows: Array<any> = []
      _.forEach(records, record => {
        rows.push(
          _.zipObject(
            columnIds,
            _.map(columnIds, colId => {
              if (!_.isString(record[colId])) {
                return record[colId]
              } else {
                return normalizeHyphens(record[colId]) // em/en dash replace
              }
            })
          )
        )
      })

      let lastRow = -1
      if (rows.length < request.endRow - request.startRow) {
        lastRow = request.startRow + rows.length
      }

      if (!_.isNil(this.eventBus) && !_.isNil(this.loadingEvent)) {
        this.eventBus.$emit(this.loadingEvent, false)
      }
      params.successCallback(rows, lastRow)
    } catch (error) {
      console.error(error)
      if (!_.isNil(this.eventBus) && !_.isNil(this.loadingEvent)) {
        this.eventBus.$emit(this.loadingEvent, false)
      }
      //@ts-ignore
      params.parentNode.gridApi.showNoRowsOverlay()
      params.failCallback()
    }
  }
}
