import _ from "lodash"
import { ApolloClient, ApolloQueryResult } from "apollo-client"
import { FetchResult } from "apollo-link"
import { DocumentNode } from "graphql"
import { CXSalesForceReturn } from "@/services/salesforce/types"
import { ISupportCase, ISupportCaseLine } from "@/services/return/types"
import { Auth0UserProfile } from "auth0-js"
import * as schema from "@/graphql/__schema"
import { createProvider } from "@/plugins/apollo"
import SalesforceService from "@/services/salesforce"
import { cx } from "@/types"

// define queries used throughout the cx service
const queries: { [key: string]: Promise<DocumentNode> } = {
  getReturnLocationId: import("@/graphql/get-return-location-id.gql"),
  getSupportCaseLineIds: import("@/graphql/get-support-case-line-ids.gql"),
}

const mutations: { [key: string]: Promise<DocumentNode> } = {
  createSupportCase: import("@/graphql/create-support-case.gql"),
  createSupportCaseLine: import("@/graphql/create-support-case-line.gql"),
  deleteSupportCase: import("@/graphql/delete-support-case.gql"),
  deleteSupportCaseLine: import("@/graphql/delete-support-case-line.gql"),
}

export default class Return {
  private readonly apollo: ApolloClient<any>
  private readonly salesforceService: SalesforceService

  constructor() {
    this.apollo = createProvider().defaultClient
    this.salesforceService = new SalesforceService()
  }

  private async getReturnSalesforceId(id: number | null): Promise<string | null> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: {
        returnLocationId: id,
      },
      query: await queries.getReturnLocationId,
    })
    return response.data.allVwReturnLocations!.nodes[0].salesforceId || null
  }

  private async createSupportCase(supportCase: ISupportCase): Promise<number> {
    const baseSupportCase = { _active: true, statusId: 4 }
    supportCase = _.assign(baseSupportCase, supportCase)
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { supportCase },
      mutation: await mutations.createSupportCase,
    })
    return response.data!.createSupportCase!.supportCase!.id
  }

  private async createSupportCaseLine(supportCaseId: number, supportCaseLine: ISupportCaseLine): Promise<number> {
    const baseSupportCaseLine = {
      _active: true,
      dispositionId: 0,
      issueId: 0,
      quantity: 1,
      supportCaseId,
    }
    supportCaseLine = {
      ...baseSupportCaseLine,
      ...supportCaseLine,
    } as ISupportCaseLine

    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { supportCaseLine },
      mutation: await mutations.createSupportCaseLine,
    })
    return response.data!.createSupportCaseLine!.supportCaseLine!.id
  }

  private async deleteSupportCase(supportCaseId: number): Promise<number> {
    const queryResponse: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { supportCaseId },
      query: await queries.getSupportCaseLineIds,
    })
    const existingLineIds: number[] = _.map(queryResponse.data.allSupportCaseLines!.nodes, "id")

    const deleteLinePromises: Array<Promise<any>> = []
    const deleteSupportCaseLine: DocumentNode = await mutations.deleteSupportCaseLine

    _.forEach(existingLineIds, supportCaseLineId => {
      const deleteMutation = this.apollo.mutate({
        variables: { supportCaseLineId },
        mutation: deleteSupportCaseLine,
      })
      deleteLinePromises.push(deleteMutation)
    })
    await Promise.all(deleteLinePromises)

    const deleteResponse: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { supportCaseId },
      mutation: await mutations.deleteSupportCase,
    })
    return deleteResponse.data!.deleteSupportCaseById!.supportCase!.id
  }

  private async createSalesforceCase(
    workingReturn: cx.Returns.Return,
    accountId: string,
    userId: string
  ): Promise<CXSalesForceReturn> {
    try {
      const salesforceIds: CXSalesForceReturn = await this.salesforceService.createReturn(
        workingReturn,
        accountId,
        userId
      )
      return salesforceIds
    } catch (error) {
      // when salesforce case creation fails
      const errorMessage = `Failed to create support case in source system`
      console.error(errorMessage, error)
      throw errorMessage
    }
  }

  private async createSalesforceSupportCase(
    workingReturn: cx.Returns.Return,
    caseId: string,
    caseNumber: string,
    userId: string
  ): Promise<number> {
    let returnLocationOverride = null
    if (
      workingReturn.productGroup.returnLocation &&
      workingReturn.productGroup.returnLocation?.id !== workingReturn.location.return.id
    ) {
      returnLocationOverride = workingReturn.location.return.id
    }

    try {
      const supportCaseId = await this.createSupportCase({
        _createdBy: userId,
        _updatedBy: userId,
        caseNumber: caseNumber,
        salesforceId: caseId,
        accountId: workingReturn.account.id || null,
        customerTag: workingReturn.tag || null,
        poNumber: workingReturn.poNumber || null,
        customerBillId: workingReturn.location.billing.id || null,
        customerShipId: workingReturn.location.shipping.id || null,
        productGroupId: workingReturn.productGroup.id || null,
        requestedActionId: workingReturn.action.id || null,
        description: workingReturn.description || null,
        returnLocationOverride: returnLocationOverride,
      })
      return supportCaseId
    } catch (error) {
      // when db case creation fails
      console.error(`Failed to create support case for case #${caseNumber}`, error)
      throw error
    }
  }

  private async createSalesforceCaseLineItems(
    workingReturn: cx.Returns.Return,
    caseNumber: string,
    caseLineIds: Array<string>,
    caseId: string,
    supportCaseId: number,
    userId: string
  ) {
    try {
      const linePromises: Array<Promise<number>> = []
      const rowData = workingReturn.rowData || null
      if (!rowData) throw "return does not have any row items!"
      _.forEach(_.zipObject(caseLineIds, rowData), (row: cx.Returns.Item, salesforceLineId: string) => {
        linePromises.push(
          this.createSupportCaseLine(supportCaseId, {
            supportCaseId: supportCaseId,
            salesforceCaseId: caseId,
            salesforceId: salesforceLineId,
            _createdBy: userId,
            _updatedBy: userId,
            dispositionId: 0,
            issueId: row.issue.id || 0,
            serialNumber: row.serialNumber || "",
            description: row.description || null,
            quantity: row.quantity || 1,
          })
        )
      })
      await Promise.all(linePromises)
    } catch (errors) {
      let errorString = errors
      console.error(`Failed to successfully create lines for support case #${caseNumber} (id:${supportCaseId})`, errors)
      try {
        await this.deleteSupportCase(supportCaseId)
      } catch (error) {
        console.error(`Failed to remove partially complete support case #${caseNumber} (id:${supportCaseId})`, error)
        errorString = `${error} thrown as a result of ${errorString}`
      } finally {
        // eslint-disable-next-line
        throw errorString
      }
    }
  }

  async createSalesforceReturn(workingReturn: cx.Returns.Return, user: Auth0UserProfile): Promise<string> {
    const accountId: string | null = await this.getReturnSalesforceId(workingReturn.location.return.id || null)
    if (!accountId) throw "accountId is null. cannot create sales force case"

    const userId: string = user.user_id
    const salesforceUserId: string | null = _.last(_.split(userId, "|")) || null
    if (!salesforceUserId) throw "salesforceUserId is null"

    const salesforceIds = await this.createSalesforceCase(workingReturn, accountId, salesforceUserId)

    const supportCaseId = await this.createSalesforceSupportCase(
      workingReturn,
      salesforceIds.caseId,
      salesforceIds.caseNumber,
      userId
    )
    await this.createSalesforceCaseLineItems(
      workingReturn,
      salesforceIds.caseNumber,
      salesforceIds.caseLineIds,
      salesforceIds.caseId,
      supportCaseId,
      userId
    )
    return salesforceIds.caseNumber
  }
}
