











































































































































import _ from "lodash"
import gql from "graphql-tag"
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete"
import { Vue, Component, Prop } from "vue-property-decorator"
import CXService from "@/services/cx"
import { cx } from "@/types"

interface IAddress {
  name: string
  country: string
  locality: string
  administrative_area_level_1: string
  postal_code: string
}
interface ICreateLocationMap {
  accountId: number
  locationId: number
}

@Component({
  name: "new-location-dialog",
  components: {
    VuetifyGoogleAutocomplete,
  },
})
export default class NewLocationDialog extends Vue {
  cxService: CXService = new CXService()
  loading: boolean = false
  complete: boolean = false
  error: string | null = null
  newLocationValid: boolean = false
  advancedEditVisible: boolean = false
  geoLocationEnabled: boolean = false
  description: string | null = null
  address: string | null = null
  country: string | null = null
  city: string | null = null
  state: string | null = null
  postalCode: string | null = null
  phoneNumber: string | null = null
  attn: string | null = null
  $refs!: {
    newLocationForm: any
    autocomplete: any
  }

  @Prop(Boolean) readonly value!: boolean
  @Prop({ type: String, default: "New Location", required: false }) readonly title!: string
  @Prop({ type: Number, required: true }) readonly accountId!: number

  get formattedAddress(): string {
    return `${this.address}, ${this.city}, ${this.state} ${this.postalCode}, ${this.country}`
  }

  get statusHtml(): string {
    if (this.isLoading && !this.isComplete) {
      return `Creating <strong>${this.description}</strong>...`
    } else {
      if (this.isComplete) {
        if (this.hasError) {
          return `<strong>${this.error}</strong>`
        } else {
          return `<strong>${this.description}</strong> Created!`
        }
      } else {
        return ""
      }
    }
  }

  get isLoading(): boolean {
    return this.loading
  }

  get isComplete(): boolean {
    return this.complete || this.hasError
  }

  get hasError(): boolean {
    return !_.isNil(this.error)
  }

  hideDialog(): void {
    this.$refs.newLocationForm.reset()
    this.$refs.autocomplete.clear()
    this.$emit("update:value", false)
    this.loading = false
    this.complete = false
    this.error = null
  }

  onPlaceChanged(addressData: IAddress | null, placeData: string | null): void {
    if (!_.isNil(addressData) && !_.isNil(placeData)) {
      this.address = addressData.name
      this.country = addressData.country
      this.city = addressData.locality
      this.state = addressData.administrative_area_level_1
      this.postalCode = addressData.postal_code
      this.$refs.newLocationForm.validate()
    }
  }

  async createLocation(location: cx.Returns.Location): Promise<number> {
    let locationData: cx.Returns.Location = _.clone(location)
    try {
      return await this.cxService.getLocationId(locationData)
    } catch (err) {
      return await this.cxService.createLocation(locationData)
    }
  }

  async create(): Promise<void> {
    this.error = null
    this.complete = false
    this.loading = true
    try {
      let locationId: number = await this.createLocation({
        description: this.description,
        address: this.address,
        country: this.country,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        phoneNumber: this.phoneNumber,
        attn: this.attn,
      } as cx.Returns.Location)

      await this.cxService.createAccountLocationMapping(this.accountId, locationId)
      this.loading = false
      this.complete = true
      this.$emit("location-created", locationId)
    } catch (error) {
      this.loading = false
      this.$raven.captureException(error)
      this.error = error
    }
  }
}
