

























































































import _ from "lodash"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Getter } from "vuex-class"
import { Auth0UserProfile } from "auth0-js"
import EmptyState from "@/components/EmptyState.vue"
import pdf from "@/services/pdf"
import jsPDF from "jspdf"
import ReturnService from "@/services/return"
import { cx } from "@/types"
import { WarrantyInfo } from "@/services/warranty/types"
import WarrantyService from "@/services/warranty"

@Component({
  name: "return-complete-step",
  components: {
    EmptyState,
  },
})
export default class ReturnCompleteStep extends Vue {
  private readonly pdfService = new pdf()
  private readonly returnService = new ReturnService()
  private readonly warrantyService = new WarrantyService()

  readonly iconSize: number = 96
  loading: boolean = true
  showActions: boolean = false
  error: string | null = null
  failed: boolean = false
  informationText: string = "Creating case..."
  closeVisible: boolean = false
  closeVisibleInterval: any = null
  loadingPDF: boolean = false
  warrantyInformation?: WarrantyInfo[]
  completedReturn: cx.Returns.Return | null = null

  mounted(): void {
    this.reset()
    this.$on("return-created", () => {
      this.completedReturn = _.clone(this.workingReturn)
      this.$store.dispatch("user/clearState")
    })
  }

  @Prop({ type: Boolean, required: true }) readonly isVisible!: boolean

  /**
   * @description Handles irrecoverable failures
   * @returns {Boolean}
   */
  get hardFailure(): boolean {
    return this.failed && !_.isNil(this.error)
  }

  /**
   * @description Handles recoverable failures (postgres database failure) & will re-sync
   * @returns {Boolean}
   */
  get softFailure(): boolean {
    return this.failed && _.isNil(this.error)
  }

  @Getter("returns/getWorkingReturn")
  workingReturn!: cx.Returns.Return

  @Getter("user/getUser")
  user!: Auth0UserProfile

  /**
   * @description Generic error handler - watches for the error property
   * @param {any} value
   */
  @Watch("error")
  errorWatcher(value: any): void {
    this.loading = _.isNil(value)
  }
  /**
   * @description Watching to handle failure toggle
   * @param {any} value
   */
  @Watch("failed")
  failedWatcher(value: boolean): void {
    this.loading = !value
  }
  /**
   * @description Watcher to handle loading toggle
   * @param {any} value
   */
  @Watch("loading")
  loadingWatcher(value: boolean): void {
    if (!value) {
      setTimeout(() => {
        this.showActions = true
      }, 500)
    } else {
      this.showActions = false
    }
  }

  /**
   * @description Function to reset the form
   */
  reset(): void {
    this.failed = false
    this.informationText = ""
    this.closeVisible = false
    this.closeVisibleInterval = setInterval(this.showClose, 500)
    this.loadingPDF = false
  }

  /**
   * @description Function to show the close dialog
   */
  showClose(): void {
    if (this.isVisible && !this.closeVisible) {
      setTimeout(() => {
        this.closeVisible = true
      }, 1000)
      clearInterval(this.closeVisibleInterval)
    }
  }

  /**
   * @description Function call to invoke the pdf service and download the return information
   */
  async downloadInformation() {
    this.loadingPDF = true
    if (!this.completedReturn) {
      throw "completedReturn is not defined"
    } else if (!this.warrantyInformation) {
      this.warrantyInformation = await this.warrantyService.fetchWarranty(this.completedReturn)
    }
    this.pdfService
      .buildReturnDocument(this.completedReturn, this.warrantyInformation)
      .save(`${this.completedReturn.referenceNumber} Packing Slip.pdf`)
    this.loadingPDF = false
  }

  async createSalesforceReturn() {
    try {
      this.loading = true
      let [referenceNumber, warrantyInfo] = await Promise.all([
        this.returnService.createSalesforceReturn(this.workingReturn, this.user),
        this.warrantyService.fetchWarranty(this.workingReturn).catch(reason => []),
      ])
      this.warrantyInformation = warrantyInfo
      this.$store.commit("returns/updateWorkingReturn", {
        referenceNumber: referenceNumber,
        createdDate: new Date().toISOString(),
      })
      this.$emit("return-created", referenceNumber)
    } catch (error) {
      //NOTE: Somewhat messy but will work with refactor
      if (error === `Failed to create support case in source system`) {
        this.error = error
      }
      this.$raven.captureException(error)
      this.failed = true
    } finally {
      this.loading = false
    }
  }
}
